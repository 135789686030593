$violet-900: #7e7ab6;
$violet-500: #B7B7D4;
$violet-300: #cbc7de6b;
$blue-500: #b4d3ea;
$blue-300: #b4d3ea69;

$primary: $violet-900;
$primary-light: $violet-500;
$secondary: #32394D;
$success: #5eb663;
$light: #a0a1a4;
$lighter-1: #b1b1b1;
$lighter-2: #e4e5e8;
$lighter-3: #ababab;
$lighter-4: #f8f8f8;
$light-gray: #d9d9d9;
$very-light: #dedede;
$very-dark-gray: #2C2C2C;
$dark-gray: #32353E;
$dark: #3f4350;
$danger: #e74d3d;
$warning: #e08900;
$border-line-1: #5b5e6c;
$border-line-2: #d2d2e4;
$border-line-3: #a0a1a4;
$border-line-4: #ecf1f5;
$white: white;
$blue: #0894dd;
$blue-light: #0a95dd;
$blue-dark: #055d8a;
$background: #fbfbfd;
$fw-semi-bold: 500;
