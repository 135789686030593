/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import '~video.js/dist/video-js.css';
@import "~assets/styles/variables";
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~assets/styles/icons";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import 'ngx-toastr/toastr';
@import "~assets/styles/custom";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~assets/styles/ngb-datepicker.scss";
