@import "src/assets/styles/variables";

.ngb-dp-weekday.small {
  color: $primary;
}

.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: $primary;
}
.custom-day.range,
.custom-day:hover {
  background-color: $primary;
  color: white;
}
.custom-day.faded {
  background-color: $primary;
}

.ngb-dp-day {
  &.disabled {
    color: $lighter-1;
  }
}
