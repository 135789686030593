.mh-icon {
  height: 1rem;
  width: 1rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;

  &-person-add {
    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/person-add--light-gray.svg");
    }
  }

  &-calendar {
    background-image: url("~assets/icons-v2/calendar.svg");
  }

  &-bars {
    background-image: url("~assets/icons-v3/bars.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/bars--light-gray.svg");
    }
  }

  &-more {
    background-image: url("~assets/icons-v3/more.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/more--light-gray.svg");
    }
  }

  &-dashboard {
    background-image: url("~assets/icons-v3/dashboard.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/dashboard--light-gray.svg");
    }
  }

  &-resume {
    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/resume--light-gray.svg");
    }
    &.mh-icon--dark-gray {
      background-image: url("~assets/icons-v3/resume--dark-gray.svg");
    }
  }

  &-book_4 {
    background-image: url("~assets/icons-v3/book_4.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/book_4--light-gray.svg");
    }
  }

  &-lab-profile {
    background-image: url("~assets/icons-v3/lab-profile.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/lab-profile--light-gray.svg");
    }
  }

  &-card-membership {
    background-image: url("~assets/icons-v3/card-membership.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/card-membership--light-gray.svg");
    }
  }

  &-person {
    background-image: url("~assets/icons-v3/person.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/person--light-gray.svg");
    }
  }

  &-logout {
    background-image: url('~assets/icons-v3/logout.svg');

    &.mh-icon--light-gray {
      background-image: url('~assets/icons-v3/logout--light-gray.svg');
    }
  }

  &-support {
    background-image: url("~assets/icons-v3/support.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/support--light-gray.svg");
    }
  }

  &-bar-chart-line-fill {
    background-image: url("~assets/icons-v2/bar-chart-line-fill.svg");
  }

  &-be {
    background-image: url("~assets/icons-v2/be.svg");
  }

  &-info {
    background-image: url("~assets/icons-v2/info.svg");
  }

  &-check {
    background-image: url("~assets/icons-v2/check.svg");

    &.mh-icon--white {
      background-image: url('~assets/icons-v2/check--white.svg');
    }
  }

  &-pdf-colored {
    background-image: url('~assets/icons-v2/pdf-colored.svg');
  }

  &-arrow-up-circle {
    &.mh-icon--white {
      background-image: url('~assets/icons-v2/arrow-up-circle--white.svg');
    }
  }

  &-cart {
    background-image: url('~assets/img/registration-icons/cart.svg');
  }

  &-speech-bubble {
    background-image: url('~assets/img/speech-bubble-with-ellipsis.svg');
  }

  &-contact-us {
    background-image: url('~assets/img/registration-icons/contact-user.svg');
  }

  &-wrench {
    &.mh-icon--black {
      background-image: url("~assets/icons-v2/wrench--black.svg");
    }
  }

  &-courses {
    background-image: url("~assets/icons-v3/courses.svg");

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v3/courses--light-gray.svg");
    }

    &.mh-icon--black {
      background-image: url("~assets/icons-v3/courses--black.svg");
    }
  }

  &-chart {
    background-image: url("~assets/icons-v2/chart.svg");
  }

  &-gear {
    background-image: url("~assets/icons-v2/gear.svg");
  }

  &-pencil {
    background-image: url("~assets/icons-v2/pencil.svg");
  }

  &-key {
    background-image: url("~assets/icons-v2/key.svg");


    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v2/key--light-gray.svg");
    }
  }

  &-test_prep {
    background-image: url("~assets/icons-v2/test-prep.svg");
  }

  &-college {
    background-image: url("~assets/icons-v2/college.svg");
  }

  &-k12 {
    background-image: url("~assets/icons-v2/k12.svg");
  }

  &-export {
    background-image: url("~assets/icons-v2/export.svg");
  }

  &-print {
    background-image: url("~assets/icons-v2/print.svg");
  }

  &-eye {
    background-image: url("~assets/icons-v2/eye.svg");
  }

  &-search {
    background-image: url("~assets/icons-v2/search.svg");

    &.mh-icon--white {
      background-image: url("~assets/icons-v2/search--white.svg");
    }
  }

  &-subscription-active {
    background-image: url("~assets/icons-v2/subscription-active.svg");
  }

  &-subscription-canceled, &-subscription-terminated, &-subscription-expired {
    background-image: url("~assets/icons-v2/subscription-canceled.svg");
  }

  &-subscription-suspended {
    background-image: url("~assets/icons-v2/subscription-suspended.svg");
  }

  &-circled-arrow {
    background-image: url("~assets/icons-v2/circled-arrow.svg");
  }

  &-credit-card {
    background-image: url('~assets/icons-v2/credit-card.svg');

    &.mh-icon--white {
      background-image: url('~assets/icons-v2/credit-card--white.svg');
    }
  }

  &-cancel {
    background-image: url('~assets/icons-v2/cancel.svg');
  }

  &-expanded {
    background-image: url('~assets/icons-v2/expanded.svg');
  }

  &-collapsed {
    background-image: url('~assets/icons-v2/collapsed.svg');
  }

  &-cross {
    background-image: url('~assets/icons-v2/cross.svg');

    &.mh-icon--white {
      background-image: url("~assets/icons-v2/cross--white.svg");
    }

    &.mh-icon--light-gray {
      background-image: url("~assets/icons-v2/cross--white.svg");
    }
  }

  &-burger-menu {
    background-image: url("~assets/icons-v2/burger-menu.svg");
  }

  &-check-circle {
    &.mh-icon--white {
      background-image: url("~assets/icons-v2/check-circle--white.svg");
    }
  }

  &-cross-circle {
    &.mh-icon--white {
      background-image: url("~assets/icons-v2/cross-circle--white.svg");
    }
  }

  &-play-circle {
    &.mh-icon--white {
      background-image: url("~assets/icons-v2/play-circle--white.svg");
    }
  }

  &-reload {
    &.mh-icon--white {
      background-image: url("~assets/icons-v2/reload--white.svg");
    }
  }

  &-arrow-left {
    background-image: url("~assets/icons-v2/arrow-circled.svg");

    &.mh-icon--white {
      background-image: url("~assets/icons-v2/arrow-circled--white.svg");
    }
  }

  &-arrow-right {
    transform: rotate(180deg);

    &.mh-icon--white {
      background-image: url("~assets/icons-v2/arrow-circled--white.svg");
    }
  }

  &-book {
    background-image: url("~assets/icons-v2/book.svg");
  }
}

$icon-sizes: (
  xs: 0.5rem,
  sm: 0.75rem,
  md: 1rem,
  lg: 1.25rem,
  xl: 1.5rem,
  xxl: 2rem,
  xxxl: 2.5rem,
  xxxxl: 3rem
);

@each $name, $size in $icon-sizes {
  .mh-icon--#{$name} {
    width: $size;
    height: $size;
    background-size: $size;
  }
}
