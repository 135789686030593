@import "variables";

body {
  background: $background;
  color: $secondary;
  font-size: 14px;
}

html {
  h4, .h4 {
    font-size: 24px;
  }

  h5, .h5 {
    font-size: 18px;
  }

  h6, .h6 {
    font-size: 16px;
  }

  small {
    font-size: 12px;
  }
}

input {
  &[type=radio] {
    color: $primary;
  }
}

.btn {
  border-radius: 100px;
  height: 44px;

  &-sm {
    height: 30px;
  }

  &-disabled {
    cursor: initial;
    opacity: 0.65;
  }
}

.form-group {
  .form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #424242;
    width: 100%;
    display: block;
    padding: 10px 20px;

    &.ng-invalid.ng-touched:not(:focus) {
      border-bottom: 1px solid $danger;

      &::placeholder {
        color: $danger;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  &--slightly-rounded {
    .form-control {
      border-radius: 8px;
      height: 40px;
      border: 1px solid $border-line-2;
      display: block;
      padding: 10px 20px;

      &.ng-invalid.ng-touched {
        border: 1px solid $danger;
        background: #DE6C7A26;

        &::placeholder {
          color: $danger;
        }
      }

      &:focus {
        box-shadow: none;
      }

    }

    .ng-select-container {
      border-radius: 8px;
      height: 40px !important;
    }
  }

  &--rounded {
    .form-control {
      border-radius: 30px;
      height: 40px;
      border: 1px solid $border-line-2;
      display: block;
      padding: 10px 20px;

      &.ng-invalid.ng-touched {
        border: 2px solid $danger;
        color: $danger;

        &::placeholder {
          color: $danger;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  //"info":       $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

.fw-semi-bold {
  font-weight: $fw-semi-bold;
}

ngb-accordion {
  .card {
    border: none;
    overflow: visible !important;
    border-radius: 3px !important;

    &-header {
      background-color: $white;
      border-bottom: none;
      padding: 0;
    }

    &-body {
    }
  }
}

.btn {
  &-text {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    padding: 0 0 0 0;
  }
}

.btn {
  &-border-only {
    background: transparent;
    color: $secondary;
  }
}

.modal, .modal-backdrop {
  z-index: 1070 !important;
}

.popover-body {
  padding: 0;
}

.btn-primary, .btn-success, .btn-light {
  color: $white;

  &:hover, &:focus, &:disabled {
    color: $white;
  }
}

a {
  text-decoration: none;
}

button {
  &:focus {
    box-shadow: none !important;
  }
}

@media(max-width: 768px) {
  .modal-dialog {
    max-width: 95vw;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .modal-dialog {
    max-width: 85vw;
  }
}

.tooltip {
  box-shadow: 0px 6px 12px 0px #00000040;
  width: 350px;
  border: 1px solid $primary-light;
  border-radius: 8px;

  .tooltip-arrow {
    display: none;
  }

  .tooltip-inner {
    padding: 1rem;
    max-width: unset;
    background: white;
    color: $secondary;
    text-align: left;
  }
}

.container {
  @media (min-width: 992px) and (max-width: 1399px) {
    max-width: 800px;
  }

  @media(min-width: 1400px) and (max-width: 1600px) {
    max-width: 1100px;
  }
}

.text-light-gray {
  color: $light-gray;
}

a {
  outline: none!important;
}
